<template>
  <div>
      <v-text-field
        v-model="localDate"
        value="localDate"
        :label=label_
        :style=style_
        :dark=dark_
        :class=class_
        :placeholder_=placeholder_
        :outlined=outlined_
        :filled=filled_
        :solo=solo_
        :background-color=backgroundColor_
        :flat=flat_
        :tabindex=tabindex_
        cache-items
        dense
        :clearable="false"
        @click:clear="clearField"
        v-mask="MASK_DATA"
      >
        <v-icon 
          v-if="localDate"
          slot="append"
          @click="clearField"
          tabindex="-1"
          class="mr-2"
        >
          mdi-close
        </v-icon>
        <v-icon 
          slot="append" 
          @click="modal = true"
          tabindex="-1"
        >
          mdi-calendar
        </v-icon>
      </v-text-field>

  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="290px"
    >
    <v-date-picker v-model="computedlocalDate" scrollable> 
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="salvar()">OK</v-btn>
    </v-date-picker>
  </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'
import { MASK_DATA } from "../../../services/constantes";

export default {
    props: {
      date: {
        type: String,
      },
      label_: {
        type: String,
      },
      dark_: {
        type: Boolean,
      },
      title:{
        type: String,
      },
      class_: {
        type: Object,
        //default: function () {
        //  return { message: 'hello' }
        //}
      },
      placeholder_:{
        type: String,
      },
      outlined_:{
        type: Boolean,
      },
      filled_:{
        type: Boolean,
      },
      solo_:{
        type: Boolean,
      },
      backgroundColor_:{
        type: String,
      },
      flat_:{
        type: Boolean,
      },
      tabindex_:{
        type: Number,
      },
      style_: {
        type: Object,
        //default: function () {
        //  return { message: 'hello' }
        //}
      }
    },

    data () {
      return {
        modal             : false,
        localDate         : null,
        computedlocalDate : null,
        classe            : this.class_,
        mask              : '##/##/####',
        MASK_DATA         : MASK_DATA,
      }
    },
    
    watch: {
      date() {
        // Sempre atualiza no Pai
        this.localDate = this.date;
      },

      localDate() {
        // Data que é mostrada no Text
        this.computedlocalDate = this.localDate
        // Data que é atualizada no props
        this.$emit('update:date', this.localDate)
        this.$emit('ok', this.localDate);

        // Data que é mostrada no Text
        moment.defaultFormat = "DD/MM/YYYY";
        this.computedlocalDate = null
        // Data que é atualizada no props
        this.$emit('update', null)
         if (this.localDate && this.localDate.length == 10) {
           // Data que é mostrada no Text
           this.computedlocalDate = moment(this.localDate, moment.defaultFormat).format('YYYY-MM-DD')

           // Data que é atualizada no props
           this.$emit('update', moment(this.localDate, moment.defaultFormat).format('DD/MM/YYYY'))
         }
         else {
           this.$emit('update', null)
         }
      },
    },
    
    methods: {
      clearField(){
        // Data que é mostrada no Text
        this.computedlocalDate = null
        // Data que é atualizada no props
        this.$emit('update', null)
        this.$emit('ok', null);
        // Data de Controle local
        this.localDate = null
      },

      salvar() {        
        //this.$emit('ok', this.localDate);
        moment.defaultFormat = "YYYY-MM-DD";
        this.localDate = moment(this.computedlocalDate, moment.defaultFormat).format('DD/MM/YYYY');        
        this.modal = false;
      },

    },

}
</script>

<style scoped>
  .inputs_header {
    margin: 0.8rem 0 1.2rem 0;
    height: 35px !important;
  }

  ::v-deep .v-input__icon--clear {
    background-color: red!important;
  }
  
</style>

